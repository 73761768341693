import React, { useState } from "react";
import { motion } from "framer-motion";

function Star({ isFavorite }) {
  const [favorite, setFavorite] = useState(isFavorite || false);
  return (
    <motion.span
      onClick={() => {
        setFavorite((e) => !e);
      }}
      whileTap={{ scale: 0.8 }}
    >
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        {favorite ? (
          <path
            d="M23.2107 8.34376L16.0202 7.26271L12.8058 0.521495C12.718 0.336925 12.5736 0.187511 12.3952 0.0966905C11.9477 -0.131825 11.404 0.0586046 11.1802 0.521495L7.96589 7.26271L0.775366 8.34376C0.577124 8.37306 0.395874 8.46974 0.257104 8.61622C0.0893395 8.7946 -0.00310648 9.03458 7.97101e-05 9.28343C0.0032659 9.53229 0.101824 9.76965 0.274096 9.94337L5.47654 15.1904L4.24744 22.5996C4.21861 22.772 4.23705 22.9492 4.30066 23.1113C4.36426 23.2734 4.47049 23.4138 4.6073 23.5165C4.74411 23.6193 4.90602 23.6804 5.07467 23.6928C5.24333 23.7053 5.41198 23.6686 5.5615 23.5869L11.993 20.0889L18.4246 23.5869C18.6002 23.6836 18.8041 23.7158 18.9995 23.6807C19.4923 23.5928 19.8236 23.1094 19.7386 22.5996L18.5095 15.1904L23.712 9.94337C23.8536 9.79982 23.947 9.61231 23.9754 9.40724C24.0518 8.89454 23.7063 8.41993 23.2107 8.34376V8.34376Z"
            fill="var(--white--900)"
          />
        ) : (
          <path
            d="M15.5689 7.47791L15.6832 7.71766L15.9459 7.75715L23.1348 8.83796C23.135 8.83799 23.1352 8.83802 23.1354 8.83805C23.3494 8.87131 23.518 9.08401 23.4808 9.33348L23.4808 9.33348L23.4801 9.33884C23.4665 9.43675 23.4222 9.52496 23.3564 9.59183C23.3563 9.59197 23.3562 9.59211 23.356 9.59225L18.1545 14.8384L17.9744 15.02L18.0163 15.2723L19.2454 22.6814L19.2455 22.6818C19.2876 22.9349 19.1215 23.151 18.9117 23.1884L18.911 23.1886C18.8268 23.2037 18.7395 23.1895 18.6658 23.1489L18.6635 23.1477L12.2319 19.6496L11.993 19.5197L11.7541 19.6496L5.3226 23.1477L5.32182 23.1481C5.2561 23.184 5.18336 23.1995 5.11146 23.1942C5.03951 23.1889 4.96884 23.1628 4.90764 23.1168C4.84627 23.0707 4.79647 23.006 4.76609 22.9286C4.73568 22.8511 4.72663 22.7655 4.74059 22.6821L4.7407 22.6814L5.9698 15.2723L6.01165 15.02L5.8316 14.8384L0.629156 9.59133L0.629125 9.5913C0.549899 9.51141 0.501597 9.39872 0.500039 9.27703C0.498484 9.15563 0.543674 9.04167 0.620749 8.95939C0.685318 8.89153 0.765435 8.85066 0.848464 8.83839L0.849703 8.8382L8.04023 7.75715L8.30289 7.71766L8.41721 7.47791L11.6304 0.73908C11.6306 0.738754 11.6307 0.738428 11.6309 0.738102C11.738 0.517833 11.9807 0.446464 12.1678 0.541984L12.1684 0.542283C12.2463 0.58194 12.3128 0.649069 12.3543 0.736268L12.3545 0.736694L15.5689 7.47791Z"
            stroke="var(--white--900)"
          />
        )}
      </svg>
    </motion.span>
  );
}

export default Star;
